.headerContainer {
  // padding: 0 3vw;
  // padding-top: 1em;
  background: rgb(1, 125, 190);
  background: linear-gradient(312deg,
      rgba(1, 125, 190, 1) 0%,
      rgba(42, 175, 213, 1) 35%,
      rgba(42, 175, 213, 1) 45%,
      rgba(17, 149, 182, 1) 55%,
      rgba(1, 132, 162, 1) 65%,
      rgba(0, 153, 78, 1) 100%);
  // min-height: 75vh;
  margin-bottom: 20vh;

  .headerBlock {
    padding: 6vw 3.5vw;
  }

  .headerContent {
    padding: 6vw 0 4vw;

    h6 {
      font-family: "poppins-thin";
      font-weight: bold;
      color: #ffffff;
      font-size: 2.5rem;
      letter-spacing: 0.01em;
    }

    h2 {
      font-family: "poppins-bold";
      color: #ffffff;
      font-size: 4rem;
      letter-spacing: 0.01em;
    }

    p {
      font-family: "poppins-thin";
      color: #ffffff;
      font-size: 1.5rem;
      font-weight: 400;
      font-style: normal;
      opacity: 0.8;
      letter-spacing: 0.01em;
    }
  }

  .bannerImageContainer {
    img {
      position: absolute;
      width: 46%;
      margin-top: 25vh;
    }
  }
}