/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
  .solution .solution-block {
    margin-left: 0px !important
  }

  .work-Container2 .alaramImg img {
    width: 100% !important;
    position: static;
  }

  .headerContainer {

    // min-height: auto !important;

    margin-bottom: 0 !important;

    // border: 2px solid red;




    .mobilebgColor {

      background: rgb(1, 125, 190);

      background: linear-gradient(312deg,

          rgba(1, 125, 190, 1) 0%,

          rgba(42, 175, 213, 1) 35%,

          rgba(42, 175, 213, 1) 45%,

          rgba(17, 149, 182, 1) 55%,

          rgba(1, 132, 162, 1) 65%,

          rgba(0, 153, 78, 1) 100%);

      // box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.36);

      // border-radius: 0 0 10px 10px;

      // padding: 15px;

    }




    .mobilebannerImage {

      display: none;

    }

    .Attendees{
      display: block !important;
      font-size: 10px;
    }
    .whiteButton {
      font-size: 10px;
  }


    .headerContent {

      padding: 20vw 4vw 4vw !important;




      h6 {

        font-size: 6vw;

      }




      h2 {

        font-size: 12vw !important;

      }




      p {

        font-size: 5vw;

      }

    }

  }




  .main-nav {

    width: 98%;

  }




  .main-header {

    .whiteButton {

      display: none;

    }

  }




  /**************About Us*************/

  .aboutusContainer {

    padding: 20px !important;




    .aboutusContent {

      p {

        justify-content: left !important;

        font-size: 15px !important;

        line-height: 25px !important;

      }

    }

  }




  .aboutUsCountSection {

    padding: 50px !important;




    .aboutUsCounter {

      margin-bottom: 40px;




      &:last-child {

        margin: 0;

      }




      p {

        margin: 0;

      }

    }

  }




  /**************We help you*************/

  .weHelpYou {

    padding: 35px !important;




    .weHelpYouContent {

      margin-bottom: 20px;




      h3 {

        min-height: 70px !important;

      }

    }

  }




  /*Features*/

  .feature-container {

    padding: 35px !important;




    .veriant {

      display: flex;

      text-align: left;

      margin: 20px 0;

    }

  }




  .solution {

    padding: 50px 30px !important;




    .solutionContainer {

      flex-direction: column;




      .caregiverImg {

        img {

          width: 100% !important;

          position: initial !important;

          height: auto !important;

          padding-bottom: 20px;

        }

      }

    }

  }




  .tesimonials-container {

    padding: 50px 0;




    // .tesimonialRightArrow {

    //   left: 42px ;

    // }




    // .tesimonialLeftArrow {

    //   right: 10px;

    // }

  }




  .work-Container {

    height: auto;


    .mapimg{
      height: 210px !important;

    }

    img {

      height: auto !important;

    }

  }




  .ourBackgroundBlock {

    width: 92% !important;

    margin: 0 auto !important;

  }

  .architecturalFlow {

    padding: 30px !important;




    .flowRepresentation {

      padding: 0 !important;

    }




    .flowContainer {

      flex-direction: column;

      align-items: center;

      margin-top: 70px;

    }

  }




  .slidercontainer {

    .img1 {

      height: 100px;

    }




    .slider-content-container {

      padding-left: 30px !important;

    }

  }




  .quoteimg {

    left: 10% !important;

  }




  /********Leader Block******/

  .leaderContainer,

  .technologyPartner {

    padding: 25px !important;

  }

  .leaderBlock {

    text-align: center;

  }

  .top-left {

    top: 0px;

    left: 8px !important;

  }




  .leaderContent {

    margin-top: 10px;

    margin-bottom: 40px;

  }




  .technologyPartner {

    h2 {

      text-align: center;

      font-size: 38px;

      line-height: 45px !important;

    }




    .partnerLogo {

      justify-content: center !important;

      margin-top: 20px !important;

    }

  }




  .reachOutToUsBlock {

    padding: 50px 25px !important;

  }




  .emailInput {

    .button {

      padding: 10px !important;

    }

  }




  .footerContainer {

    padding: 30px !important;




    .socialMediaIcons {

      margin-bottom: 20px;

    }

  }




  .footerBottom {

    text-align: center;

    padding: 10px 25px !important;

  }

  .footerContent {

    margin-bottom: 20px;

  }

  /*New Testimoinals*/

  .tesimonials-container {

    padding: 40px 0 !important;

  }




  .testimonialPersonImage {

    width: 150px !important;
    height: 150px;
    z-index: 1;
    // margin: 0 auto;
    margin-left: 85px;
    border-radius: 100%;
    background: #616161;

  }




  .testimonialContentBlock {

    flex-direction: column;

    .testimonialContent {
      background-color: #197039;
      padding: 20px;
      margin-left: auto;
      color: #ffffff;
      margin-top: -50px;
      height: 330px !important;
      width: 115% !important;

      .testimonial {
        margin-top: 25px !important;
      }
    }
  }

  .ourBackgroundContainer {

    padding: 68px 0;

    .ourBackgroundBlock {

      background: #ffffff !important;

    }

  }

}




/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 800px) {

  .main-header {}




  .headerContainer {

    // min-height: 43vh !important;

    margin-bottom: 10vh !important;

  }




  .aboutUsCountSection .aboutUsCounter h3 {

    font-size: 32px !important;

  }




  .feature-container {

    padding: 108px 0px !important;
    margin: 0px 100px;

  }

  .feature-container2 {

    padding: 108px 0px !important;
    margin: 0px -5px !important;

  }




  .architecturalFlow {

    padding: 108px 23px 0 !important;




    .flowContent {

      min-height: 35vh;

    }


  }

}




/*******ipad mini and air*******/

@media only screen and (min-width: 601px) and (max-width: 800px) {

  .main-header {

    .whiteButton {

      // display: none !important;

    }




    .work-Container {

      img {

        height: auto !important;

      }

    }

  }




  .headerContainer {

    // min-height: 40vh !important;




    .bannerImageContainer {

      display: none;

    }

  }




  .aboutusContainer {

    padding: 0px 50px 20px !important;

  }




  .weHelpYou {

    padding: 90px 21px !important;




    .weHelpYouContent {

      min-height: 53vh !important;




      h3 {

        min-height: 70px !important;

      }

    }

  }




  .solution {

    padding: 50px 15px !important;




    .solutionContainer {

      flex-direction: column;




      .caregiverImg {

        text-align: center;




        img {

          width: 60%;




          margin-top: 30px;

        }

      }

    }

  }




  .feature-container {

    padding: 108px 26px;

  }




  /*******leaders*****/

  .leaderContainer {

    padding: 108px 17px;




    .irfQHB {

      left: 33px;

    }




    .jgjGXD {

      right: 30px;

    }




    .top-left {

      top: 0px;

    }

  }




  /*****Flow Representation****/

  .architecturalFlow {

    padding: 74px 11px 0 !important;




    .flowContent {

      min-height: 34vh;

    }




    .flowImage {

      width: 105px;

    }




    .flowArrow {

      .arrow {

        height: 21px;

      }

    }

  }

}




@media only screen and (min-width: 801) and (max-width: 1180) {

  // body {

  //   border: 5px solid red !important;

  // }




  .headerContainer {

    margin-bottom: 0 !important;

    min-height: 40vh !important;

  }

}




@media only screen and (min-width: 1024px) and (max-height: 1366px) {}




/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) {}




/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) {}




/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {}