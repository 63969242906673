.loader {
    position: fixed;
    width: 100%;
    top: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $headlineBlue;
    opacity: 0.8;
    z-index: 2;
}