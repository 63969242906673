@import './Styles/index';

@font-face {
  font-family: poppins-regular;
  src: url("../src/Styles/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: poppins-bold;
  src: url("../src/Styles/fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: poppins-medium;
  src: url("../src/Styles/fonts/Poppins-Medium.ttf");
}
@font-face {
  font-family: poppins-thin;
  src: url("../src/Styles/fonts/Poppins-Thin.ttf");
}
body {
  font-family: GT-Regular;
}
