.footerContainer {
  background-color: #197039;
  padding: 50px 70px;
  color: #ffffff;

  .socialMediaIcons {
    margin-top: 14px;
    margin-left: 25px;
    img {
      margin-right: 10px;
    }
  }

  .footerContent {
    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
    }

    ul {
      padding: 0;
      display: block;

      li {
        list-style-type: none;

        img {
          margin-right: 10px;
        }
      }
    }

    a {
      color: #ffffff;
      text-decoration: none;
      font-style: normal;
      font-weight: 100;
      font-size: 16px;
      line-height: 30px;
    }
  }
}

.footerBottom {
  background-color: #197039;
  padding: 15px 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #307e4d;

  .row {
    width: 100%;
  }

  p {
    color: #ffffff;
    margin: 0;
  }

  .secureContent {
    color: #ffffff;

    img {
      float: left;
      margin-right: 10px;
    }

    p {
      margin-top: 5px;
    }
  }

  .payWithConfidence {
    img {
      margin-right: 20px;
      margin-top: 5px;
    }
  }
}