.whiteButton {
  border-radius: 20px;
  padding: 10px 45px;
  color: #339657;
  font-size: 16px;
  // font-weight: 600;
  border: none;
  font-family: "poppins-medium";

  &:hover {
    background: #339657;
    color: #ffffff;
  }
}

.Attendees{
  display: none !important;
}
.primaryButton {
  border-radius: 20px;
  padding: 10px 45px;
  color: #ffffff;
  font-size: 16px;
  background: #339657;
  border: none;
  font-family: "poppins-medium";
  border: 1px solid #339657;

  &:hover {
    background: #ffffff;
    border: 1px solid #339657;
    color: #339657;
  }
}

.linkButton {
  color: #460b2f;
}

/*********File css********/
.openafile {
  border-radius: 21px;
  padding: 10px 30px;
  font-size: 16px;
  border: none;
  font-family: "poppins-medium";
  background: #339657;
  color: #ffffff;
  margin: 10px 30px 10px 0px;
}

.buttonalign {
  text-align: center;
}