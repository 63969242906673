.reachOutToUsBlock {
  background-color: #ffffff;
  padding: 50px 81px;
  h4 {
    font-family: "poppins-medium";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.05em;
    color: #1a1a1a;
    text-align: center;
  }
  h2 {
    font-family: "poppins-bold";
    font-style: normal;
    font-weight: 700;
    text-align: center;
    font-size: 42px;
    background: linear-gradient(93.89deg, #00994e 0.71%, #007cbf 68.79%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin-bottom: 50px;
  }

  .emailInput {
    border-radius: 40px;
    border: 1px solid #999999;
    margin-top: 14px;
    display: flex;
    .MuiInputBase-root {
      padding: 0 30px;
      font-family: "poppins-regular";
    }
    .MuiIconButton-root {
      padding: 10px;
    }

    .MuiIconButton-root {
      &:hover {
        background-color: transparent;
      }
    }
  }
}
