.tesimonials-container {
  padding: 108px 81px;
  background-color: #00994e;
  background-image: url(../images/testimonialsBg.png);
  background-position: left top;
  background-repeat: no-repeat;
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #ffffff;
  }
  h2 {
    text-align: center;
    font-family: "poppins-medium";
    font-size: 38px;
    line-height: 63px;
    color: #ffffff;
    margin-bottom: 74px;
  }
}
.backarrow {
  height: 50px;
  position: absolute;
}
.forwardarrow {
  height: 50px;
  position: absolute;
  float: right;
  right: 0px !important;
} ///////===>Slider
.testimonialContentBlock {
  display: flex;
  .testimonialPersonImage {
    width: 15%;
    z-index: 1;
  }
  .testimonialContent {
    background-color: #197039;
    padding: 50px 20px 20px 70px;
    margin-left: -55px;
    color: #ffffff;
    height: 280px;
    .name {
      font-style: normal;
      font-weight: 500;
      font-size: 12.7248px;
      line-height: 19px;
      margin-top: 16px;
      margin-bottom: 3px;
    }
    .designation {
      font-family: "Open Sans";
      font-style: italic;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      opacity: 0.8;
    }
    .testimonial {
      margin-top: 10px;
      margin-bottom: 2px !important;
      font-size: 16px;
    }
    .ttwidth{
      width: '180%';
    }
    
    .kTvpDU{
    width:"100%" !important

    }
  }
}
