$white: #FFFFFF;
$headlineBlue: #253442;
$lightBlue: #354754;
$bgColor: #1D2A36;
$green: #1A7E36;
$lightGreen: #D1E5D7;
$red: #FD0C11;
$grey: rgba(212, 200, 200, 0.326);
$darkGrey: rgb(58, 55, 55);
$inputTitle: #929AA1;
$scarlet: #D0021B;
$alertRedBg: #EDB1B8;
$starYellow: #EEA648;
$fadedPink: #F0D2D3;
$brick: #C02220;
$textColor: #43505C;
