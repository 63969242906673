.aboutusContainer {
  padding: 150px 50px;

  .aboutusHeading {
    h6 {
      font-family: "poppins-medium";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 0.05em;
      color: #1a1a1a;
    }

    h4 {
      font-family: "poppins-bold";
      font-style: normal;
      font-weight: 700;
      font-size: 42px;
      background: linear-gradient(93.89deg, #00994e 0.71%, #007cbf 68.79%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }

  .aboutusContent {
    p {
      font-weight: 400;
      font-size: 22px;
      line-height: 36px;
      color: #1a1a1a;
      opacity: 0.7;
      text-align: justify;
    }
  }

  .mb-30 {
    margin-bottom: 30px !important;
  }
}

/* AboutUs Counter */
.aboutUsCountSection {
  background-color: #197039;
  background-image: url(../images/aboutUsBg01.png),
    url(../images/aboutUsBg02.png);
  background-position: left bottom, right top;
  background-repeat: no-repeat, no-repeat;
  padding: 70px;

  .aboutUsCounter {
    color: #ffffff;

    h3 {
      font-size: 34px;
      font-family: "poppins-medium";
      font-weight: 600;

      span {
        font-weight: 600 !important;
        font-family: "poppins-thin";
        margin-left: 14px;
      }

    }

    .mb-30 {
      margin-bottom: 30px;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 1.2px
    }
  }
}

/* We Help You */
.weHelpYou {
  .weHelp {
    .weHelpYouContentContainer {
      display: flex;
      flex-direction: row;

      .contentWidth {
        margin: 15px;
        width: 33%;
      }
    }

  }
}


.weHelpYou {
  background: #f7f7f7;
  padding: 108px 81px;

  h4 {
    font-family: "poppins-medium";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.05em;
    color: #1a1a1a;
    text-align: center;
  }

  h2 {
    font-family: "poppins-bold";
    font-style: normal;
    font-weight: 700;
    text-align: center;
    font-size: 42px;
    background: linear-gradient(93.89deg, #00994e 0.71%, #007cbf 68.79%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin-bottom: 50px;
  }

  .weHelpYouContent {
    padding: 24px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    min-height: 360px;

    h3 {
      font-family: "poppins-medium";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #000000;
      margin-top: 20px;
      margin-bottom: 8px;
    }

    p {
      font-family: "poppins-regular";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #1a1a1a;
      margin: 0;
    }
  }
}

/* Solution */

.solution {
  // background: #f9f9f9;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 2%, rgba(249, 249, 249, 1) 2%, rgba(249, 249, 249, 1) 100%);
  padding: 108px 81px 0;

  .solutionContainer {
    display: flex;
  }

  h4 {
    font-family: "poppins-medium";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.05em;
    color: #1a1a1a;
    text-align: center;
  }

  h2 {
    font-family: "poppins-bold";
    font-style: normal;
    font-weight: 700;
    text-align: center;
    font-size: 42px;
    background: linear-gradient(93.89deg, #00994e 0.71%, #007cbf 68.79%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin-bottom: 50px;
  }

  .solution-block {
    margin-top: 30px;
    margin-left: 55px
  }
}

.caregiverImg>img {
  width: 100%;

}

.solutionContent {
  background: #f1f1f1;
  border-radius: 10px;
  margin: 10px;
  padding: 15px;
  display: flex;
  align-items: center;
}

.solutionContent>img {
  margin: 0 15px 0 0;
  width: 15px;
  height: 12px;
}

/* Flow Representation */
.architecturalFlow {
  background: #f9f9f9;
  margin-top: 50px;
  padding: 108px 81px 0;

  h4 {
    font-family: "poppins-medium";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.05em;
    color: #1a1a1a;
    text-align: center;
  }

  h2 {
    font-family: "poppins-bold";
    font-style: normal;
    font-weight: 700;
    text-align: center;
    font-size: 42px;
    background: linear-gradient(93.89deg, #00994e 0.71%, #007cbf 68.79%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin-bottom: 50px;
  }
}

.flowRepresentation {
  padding: 50px;
}

.flow {
  text-align: center;
  position: relative;
}

.flowArrow {
  display: flex;
  align-items: center;
}

.flowImage {
  padding: 5px;
  width: 135px;
  background: #ffffff;
  border-radius: 10px;
  position: absolute;
  // right: 20%;
  // left: 20%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: -55px;
}

.flowContainer {
  display: flex;
  padding: 0;
}

.flowContent {
  min-height: 195px;
  padding: 70px 20px 20px;
  background: #ffffff;
  border-radius: 10px;
  font-size: 14px;
  color: #1a1a1a;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
}

/* Our Technology Partner */
.technologyPartner {
  background: #f4f4f4;
  padding: 108px 81px;

  h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 63px;
    background: linear-gradient(93.89deg, #00994e 0.71%, #007cbf 68.79%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  h4 {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #1a1a1a;
  }

  img {
    width: 100%;

  }

  .partnerLogo {
    display: flex;
    justify-content: end;
  }
}