header {
  min-height: 3.5rem;
}

.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "poppins", sans-serif;
  padding: 2.5vh 3vw;
  // background: red;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.bgColor {
  background: rgb(1, 125, 190);
  background: linear-gradient(312deg,
      rgba(1, 125, 190, 1) 0%,
      rgba(42, 175, 213, 1) 35%,
      rgba(42, 175, 213, 1) 45%,
      rgba(17, 149, 182, 1) 55%,
      rgba(1, 132, 162, 1) 65%,
      rgba(0, 153, 78, 1) 100%);

}

.brand-logo {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  color: inherit;
  text-decoration: none;
  color: #fff;
  font-family: "poppins", cursive, sans-serif;
  font-weight: 200;
  margin-left: 3vw;
  margin-top: 10px;
}

.brand-logo-name {
  font-size: 1rem;
}

ul {
  display: flex;
  margin: 0;
  padding: auto;
  list-style: none;
}

.main-nav a {
  color: #ffffff;
  text-decoration: none;
  padding: 0 1rem;
  font-family: "poppins-regular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.main-nav a:hover {
  color: #9eeaff;
}

.toggle-button {
  display: none;
}

.toggle-button .bar {
  height: 3px;
  width: 100%;
  background-color: rgb(255, 255, 255);
  border-radius: 2px;
}

@media only screen and (max-width: 1000px) {
  header {
    padding: 0 5vw;
  }
}

@media only screen and (max-width: 800px) {
  .toggle-button {
    position: absolute;
    right: 30px;
    top: 25px;
    flex-direction: column;
    justify-content: space-between;
    height: 25px;
    width: 30px;
    cursor: pointer;
    display: flex;
  }

  .main-header {
    flex-direction: column;
    align-items: flex-start;
    padding: 2.5vw 0 !important;
  }

  .main-nav ul {
    flex-direction: column;
    align-items: flex-start;
    display: none;
    padding: 0;
  }

  .main-nav li {
    padding: 0.6rem 1rem;
    border-bottom: 1px solid rgba(42, 175, 213, 1);
  }

  .main-nav a {
    padding: 0;
  }

  .main-nav ul.active {
    display: block;
    width: 100%;
  }
}