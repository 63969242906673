.feature-container {

  // display: flex;

  // justify-content: center;

  // align-items: center;

  padding: 108px 81px;




  h4 {

    font-family: "poppins-medium";

    font-style: normal;

    font-weight: 500;

    font-size: 24px;

    line-height: 36px;

    letter-spacing: 0.05em;

    color: #1a1a1a;

    text-align: center;

  }




  h2 {

    font-family: "poppins-bold";

    font-style: normal;

    font-weight: 700;

    text-align: center;

    font-size: 42px;

    background: linear-gradient(93.89deg, #00994e 0.71%, #007cbf 68.79%);

    -webkit-background-clip: text;

    -webkit-text-fill-color: transparent;

    background-clip: text;

    margin-bottom: 50px;

  }

}




.featureItems {

  text-align: center;

}




.veriant {

  background-color: #f1f1f1;

  border-radius: 40px;

  padding: 15px 20px 15px 15px;

  overflow: auto;

  margin: 10px;

  display: inline-block;

  font-style: normal;

  font-weight: 500;

  font-size: 16px;

  line-height: 30px;

  color: #000000;

}




.img-pl {

  padding-right: 10px;

}




.color {

  background-color: #00994e;

  color: #ffffff;

  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.36);

}




///////////////=>how it wokrs
.work-Container2 {

  background-color: #f1f1f1 !important;

  border-radius: 10px;

  padding: 40px 32px 0;

  min-height: 400px;

  position: relative;

  margin-bottom: 50px;

  .container-feature {
    min-height: 600px !important;
  }

  .worlItem {
    padding-right: 64px;
    padding-left: 33px;
  }


  h4 {

    text-align: left;

    font-family: "poppins-medium";

    font-style: normal;

    font-weight: 500;

    font-size: 24px;

    line-height: 36px;

    letter-spacing: 0.05em;

    color: #1a1a1a;

  }




  h2 {

    font-family: "poppins-bold";

    font-style: normal;

    font-weight: 700;

    text-align: center;

    font-size: 42px;

    background: linear-gradient(93.89deg, #00994e 0.71%, #007cbf 68.79%);

    -webkit-background-clip: text;

    -webkit-text-fill-color: transparent;

    background-clip: text;

    margin-bottom: 50px;

  }




  img {

    width: 80%;

    height: 60%;

    position: absolute;

    bottom: 0;

  }




  ol {

    margin-bottom: 20px;

    padding-left: 1rem !important;

  }

  .alaramImg {
    img {
      width: 60% !important;

      height: 96%;

      position: absolute;

      bottom: 0;
      top: 4%;
    }
  }


}


.work-Container {

  background-color: #f1f1f1 !important;

  border-radius: 10px;

  padding: 40px 47px 0;

  min-height: 600px;

  position: relative;

  margin-bottom: 20px;

  .container-feature {
    min-height: 600px !important;
  }


  h4 {

    text-align: left;

    font-family: "poppins-medium";

    font-style: normal;

    font-weight: 500;

    font-size: 24px;

    line-height: 36px;

    letter-spacing: 0.05em;

    color: #1a1a1a;

  }




  h2 {

    font-family: "poppins-bold";

    font-style: normal;

    font-weight: 700;

    text-align: center;

    font-size: 42px;

    background: linear-gradient(93.89deg, #00994e 0.71%, #007cbf 68.79%);

    -webkit-background-clip: text;

    -webkit-text-fill-color: transparent;

    background-clip: text;

    margin-bottom: 50px;

  }




  img {

    width: 80%;

    height: 60%;

    position: absolute;

    bottom: 0;

  }




  ol {

    margin-bottom: 20px;

    padding-left: 1rem !important;

  }

  .alaramImg {
    img {
      width: 60% !important;

      height: 60%;

      position: absolute;

      bottom: 0;
      top: 4%;
    }
  }


}




/////////=>Leaders

.leaderContainer {

  background-size: contain;

  padding: 108px 81px;

  background-color: #00994e;

  background-image: url(../assets/images/ourLeaderBg.png);

  background-position: right top;

  background-repeat: no-repeat;




  h4 {

    font-style: normal;

    font-weight: 500;

    font-size: 24px;

    line-height: 36px;

    letter-spacing: 0.05em;

    text-align: center;

    color: #ffffff;

  }




  h2 {

    text-align: center;

    font-family: "poppins-medium";

    font-size: 38px;

    line-height: 63px;

    color: #ffffff;

    margin-bottom: 74px;

  }

}




.leader-containder {

  height: 310px;

  // width: 300px;

  position: relative;

  background-color: rgba(0, 0, 0, 0.822);

  color: #80ec5a;

  background-color: #80ec5a;

}




.top-left {

  position: absolute;

  top: -5px;

  left: -5px;

  border-left: 5px solid #80ec5a;

  border-top: 5px solid #80ec5a;

  height: 90%;

  width: 70%;

}




.leaderBlock {

  position: relative;

  margin: 0 auto;

}




.element-class {

  padding: 0 10px 10px 0;

  background: linear-gradient(#80ec5a 0 0) 100% 100% / 40% 40% no-repeat;

  width: 90%;

}




.leaderContent {

  color: #ffffff;

  text-align: center;

  margin-top: 20px;
  margin-right: 18px;




  h5 {

    font-family: "poppins-medium";

    font-size: 25px;

    line-height: 39px;

  }

}




///////=>Our Background

.ourBackgroundContainer {

  padding: 68px 81px;




  h4 {

    font-family: "poppins-medium";

    font-style: normal;

    font-weight: 500;

    font-size: 24px;

    line-height: 36px;

    letter-spacing: 0.05em;

    color: #1a1a1a;

    text-align: center;

  }




  h2 {

    font-family: "poppins-bold";

    font-style: normal;

    font-weight: 700;

    text-align: center;

    font-size: 42px;

    background: linear-gradient(93.89deg, #00994e 0.71%, #007cbf 68.79%);

    -webkit-background-clip: text;

    -webkit-text-fill-color: transparent;

    background-clip: text;

  }




  // hr {

  //   position: "absolute";

  //   margin-left: -10;

  //   float: "right";

  //   direction: "ltr";

  //   color: "#80EC5A";

  //   height: 10;

  // }

  .ourBackgroundBlock {

    margin-top: 70px;

    background: rgb(255, 255, 255);

    background: linear-gradient(90deg,

        rgb(255, 255, 255) 49.8%,

        rgb(0, 153, 78) 49.9%,

        rgb(0, 153, 78) 50%,

        rgb(255, 255, 255) 50%);

  }

}




.wrapper_1 {

  // border-right: 2px solid #00994e;

  display: flex;

  justify-content: space-between;

  align-items: center;

  position: relative;




  hr {

    border: 1px solid #00994e;

    opacity: 1;

    width: 60px;
    top: 100;


  }




  .contentBlock {

    padding: 10px 60px;

    text-align: center;




    h3 {

      font-family: "poppins-bold";

      font-size: 32px;

      line-height: 48px;

      text-align: center;

      color: #197039;

    }




    h6 {

      font-family: "poppins-medium";

      font-style: normal;

      font-weight: 500;

      font-size: 20px;

      line-height: 30px;

      text-align: center;

      color: #1a1a1a;

      opacity: 0.8;

    }




    p {

      font-family: "poppins-regular";

      font-style: normal;

      font-weight: 400;

      font-size: 16px;

      line-height: 24px;

      text-align: center;

      color: #1a1a1a;

      opacity: 0.7;


    }

  }

}

.wrapper_2 {

  // border-left: 2px solid #00994e;

  display: flex;

  justify-content: space-between;

  align-items: center;

  position: relative;




  hr {

    border: 1px solid #00994e;

    opacity: 1;

    width: 60px;

  }




  .contentBlock {

    padding: 10px 60px;

    text-align: center;




    h3 {

      font-family: "poppins-bold";

      font-size: 32px;

      line-height: 48px;

      text-align: center;

      color: #197039;

    }




    h6 {

      font-family: "poppins-medium";

      font-style: normal;

      font-weight: 500;

      font-size: 20px;

      line-height: 30px;

      text-align: center;

      color: #1a1a1a;

      opacity: 0.8;

    }




    p {

      font-family: "poppins-regular";

      font-style: normal;

      font-weight: 400;

      font-size: 16px;

      line-height: 24px;

      text-align: center;

      color: #1a1a1a;

      opacity: 0.7;

    }

  }

}




.contentBlock {

  &::before {

    border: 2px solid red;

  }

}

.testright {

  position: absolute;

  right: 0px;

  background-color: #00994e !important;
  top: 100px;
  
}




.testleft {

  position: absolute;

  left: 0;


  background-color: red !important;

}




/********Testimonial********/

// .tesimonialRightArrow {

//   left: 42px !important;

// }




// .tesimonialLeftArrow {

//   right: 30px;

// }