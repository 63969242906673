body {
  font-family: "poppins-regular";
  width: 100vw;
  overflow-x: hidden !important;

  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

// @import url(FontFamily.scss);
@import url(Responsive.scss);
@import url(Buttons.scss);
@import url(Navbar.scss);
@import url(Header.scss);
@import url(AboutUs.scss);
@import url(Testmonials.scss);
@import url(Reachout.scss);
@import url(Footer.scss);

.wrapper {
  background-color: #f7f7f7;

  /************Header****************/
  .header {
    .mobileLogo {
      display: none;
    }

    .desktopLogo {
      display: block;

      img {
        width: 100%;
      }
    }

    .navigationSection {
      width: 100%;

      .navbar {
        // background: rgba(85, 30, 30, 0.4);
        // .blurNavbar * {
        background-color: rgba(70, 11, 47, 0.4);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        // }
        position: fixed;
        padding: 15px 65px;
        right: 0;
        font-family: "jost-regular";
        z-index: 9;

        .nav-link {
          box-shadow: inset 0 0 0 0 #ffffff;
          color: #ffffff;
          padding: 10px;
          margin: 0 25px;
          transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
        }

        .nav-link:hover {
          color: rgb(120, 15, 26);
          box-shadow: inset 200px 0 0 0 #ffffff;
        }
      }

      .otherButtons {
        .textLink {
          img {
            margin-right: 5px;
          }

          color: #ffffff;
        }

        .loginButton {
          img {
            margin-right: 5px;
          }

          margin: 0 20px;
          border: 1.5px solid #ffffff;
          background-color: transparent;
          border-radius: 0px;
          padding: 0 10px;
        }
      }
    }
  }
  .Attendees{
    display: none !important;
  }
  .bannerContent {
    color: #ffffff;
    width: 100%;

    .col {
      padding: 0;
    }

    .bannerImage {
      img {
        height: 100%;
      }
    }

    .createYourOwnBlock {
      padding: 110px 75px;
      background: rgb(92, 36, 36);
      background: linear-gradient(
        326deg,
        rgba(92, 36, 36, 1) 15%,
        rgba(70, 11, 47, 1) 61%
      );

      h2 {
        font-family: "Georgia-Regular";
        font-weight: bold;
        color: #dccdd0;
      }

      a {
        color: #ffffff;
      }

      button {
        margin: 10px;
      }
    }

    h2 {
      font-family: "Marcellus-Regular";
      font-size: 50px;
      margin-bottom: 10px;
    }

    h1 {
      font-family: "Marcellus-Regular";
      font-size: 70px;
      margin-bottom: 35px;
    }

    p {
      margin-bottom: 20px;
    }

    ul.customizeOptionBlock {
      width: 350px;
      padding-left: 15px;
      margin-bottom: 35px;

      li {
        margin-bottom: 15px;
        line-height: 25px;

        &::marker {
          content: ".  ";
          font-size: 1.2em;
        }
      }
    }

    ul.otherLinks {
      width: 300px;

      li {
        list-style-type: none;
        margin-bottom: 25px;
        font-size: 18px;

        img {
          margin-right: 20px;
        }
      }
    }
  }

  .bannerText {
    position: absolute;
    font-size: 225px;
    bottom: -50px;
    width: 100%;
    text-align: center;
    font-family: "FiraSans-Bold";
    color: #ffffff;
    font-weight: 800;
    letter-spacing: 40px;
    opacity: 4%;
  }
}
.mainContainer {
  width: 100%;
}
